window.Client = {
	init: function() {
		 const widgetElements = document.querySelector("heyflow-wrapper").flow.body.querySelector(".main").querySelectorAll(".trustpilot-widget")
	    widgetElements.forEach((e) => {
	    window.Trustpilot.loadFromElement(e, "5419b732fbfb950b10de65e5");
	    })
	},

	onRender: function(screenId) {
		// Do something when the flow renders
	}
}